<template>
    <div>
        <h2>Search</h2>
        <b-spinner label="Loading..." v-if="loading"></b-spinner>
        <div v-else>
            <b-row>
                <b-col>
                    <label>Hotel</label>
                    <vue-multiselect
                        v-model="hotel"
                        :options="filters.hotels"
                        placeholder="Hotel"
                        :searchable="false"
                        :show-labels="true"
                        :allow-empty="false"
                        track-by="id"
                        label="text"
                    ></vue-multiselect>
                </b-col>
                <b-col>
                    <label>Type</label>
                    <vue-multiselect
                        v-model="type"
                        :options="filters.types"
                        placeholder="Type"
                        :searchable="false"
                        :show-labels="true"
                        :allow-empty="false"
                    ></vue-multiselect>
                </b-col>
                <b-col>
                    <label>Payee</label>
                    <vue-multiselect
                        v-model="payee"
                        :options="filters.payees"
                        placeholder="Payee"
                        :searchable="true"
                        :show-labels="true"
                        :allow-empty="false"
                    ></vue-multiselect>
                </b-col>

            </b-row>
            <b-row>
                <b-col class="form-group">
                    <label>From Date</label>
                    <b-form-input
                        type="date"
                        v-model="fromDate"
                        placeholder="From Date"
                    ></b-form-input>
                </b-col>
                <b-col class="form-group">
                    <label>To Date</label>
                    <b-form-input
                        type="date"
                        v-model="toDate"
                        placeholder="To Date"
                    ></b-form-input>

                </b-col>
                <b-col>
                    <label>Invoice Number</label>
                    <b-form-input
                        v-model="invoiceNumber"
                        type="text"
                        placeholder="Invoice Number"
                    ></b-form-input>
                </b-col>
                <b-col class="form-group">
                    <label>Amount</label>
                    <table>
                        <tr>
                            <td>
                                <b-select v-model="amountProbe" :options="probes"
                                          style="width: 60px; float:right"
                                ></b-select>
                            </td>
                            <td>
                                <b-form-input
                                    v-model="amount"
                                    type="number"
                                    placeholder="Amount"
                                ></b-form-input>
                            </td>
                        </tr>
                    </table>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <label>Reference</label>
                    <b-form-input
                        v-model="reference"
                        type="text"
                        placeholder="Reference"
                    ></b-form-input>
                </b-col>
                <b-col>
                    <label>Check Number</label>
                    <b-form-input
                        v-model="check_number"
                        type="text"
                        placeholder="Check Number"
                    ></b-form-input>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-button variant="primary" size="sm" class="float-lg-right" @click="search()" style="margin: 10px">
                        Search
                    </b-button>
                </b-col>
            </b-row>
        </div>
        <hr/>
        <b-spinner v-if="loadingSearch"/>
        <transactions-table v-if="!loadingSearch" :data="data"/>

    </div>
</template>

<script>

import TransactionsTable from "./TransactionsTable.vue";

export default {
    name: "Search",
    components: {TransactionsTable},
    comments: {
        TransactionsTable
    },
    props: {
        companyId: String
    },
    data() {
        return {
            loading: false,
            loadingSearch: false,
            type: null,
            payee: null,
            hotel: null,
            fromDate: null,
            toDate: null,
            invoiceNumber: null,
            amountProbe: '=',
            probes: ['=', '>', '>=', '<', '<='],
            amount: null,
            reference: null,
            check_number: null,
            filters: {
                types: [],
                hotels: [],
                payees: []
            },
            data: []
        };
    },
    watch: {},
    computed: {
        hotelSearchValue() {
            if (this.hotel) {
                return this.hotel?.value;
            }
            return null;
        },
        dataSearch() {
            return {
                type: this.type,
                payee: this.payee,
                hotel: this.hotelSearchValue,
                fromDate: this.fromDate,
                toDate: this.toDate,
                invoiceNumber: this.invoiceNumber,
                amountProbe: this.amountProbe,
                amount: this.amount,
                reference: this.reference,
                check_number: this.check_number,
            };
        }
    },
    methods: {
        monthlyView(monthYear) {
            alert(monthYear);
        },
        loadFilters() {
            const url = '/search/' + this.companyId + '/filters';
            axios.get(url).then(response => {
                this.filters = response.data;
                if (this.filters.hotels.length === 1) {
                    this.hotel = this.filters.hotels[0];
                }
            });
        },
        search() {
            const url = '/search/' + this.companyId + '/search';
            this.data = [];
            this.loadingSearch = true;
            axios.post(url, this.dataSearch)
                .then(response => {
                    this.data = response.data;
                    this.loadingSearch = false;
                });
        }
    },
    created() {
        this.$root.$on('monthly-view', this.monthlyView);
    },
    mounted() {
        this.loadFilters()
        this.data = [];
        this.toDate = new Date();
        this.fromDate = new Date(new Date().setDate(this.toDate.getDate() - 90));

        this.toDate = this.toDate.toISOString().split('T')[0];
        this.fromDate = this.fromDate.toISOString().split('T')[0];


    }


}
</script>

<style scoped>

</style>
