<template>
    <div>
        <vue-confirm-dialog></vue-confirm-dialog>
        <div v-if="processed" class="alert-info alert">
            <h2>Processed</h2>
        </div>
        <div v-if="!processed" style="padding: 10px;">
            <b-button size="sm" variant="primary" style="margin-top: 5px;float:right" @click="markAsProcessed">
                Mark as Processed
            </b-button>
        </div>
        <div class="row header1">
            <div class="col-12">
                Folio No: {{ folioNumber }}
            </div>
        </div>
        <div class="row">
            <table class="col-12">
                <tr class="header">
                    <th>Hotel</th>
                    <th>Type</th>
                    <th>Paid By</th>
                    <th>Reference</th>
                    <th>Invoice #</th>
                    <th>Check #</th>
                    <th>Deposit Date</th>
                    <th>Deposit Amount</th>
                </tr>
                <template v-for="item in batch">
                    <tr v-if="item.rowType == 'hotel-header'" class="hotel-header">
                        <td colspan="9">{{ item.hotelId }}</td>
                    </tr>
                    <tr v-else-if="item.rowType == 'data-row'" class="data-row">
                        <td>
                            <b-link @click="editLink(item)">Edit</b-link>
                        </td>
                        <td>{{ item.transaction_type }}</td>
                        <td>{{ item.paid_by }}</td>
                        <td>{{ item.reference }}</td>
                        <td>{{ item.invoice_number ? item.invoice_number : '-' }}</td>
                        <td>{{ item.check_number }}</td>
                        <td>{{ item.deposit_date }}</td>
                        <td class="currency">{{ item.deposit_amount |currency }}</td>
                    </tr>
                    <tr v-else-if="item.rowType == 'type-footer'" class="type-footer">
                        <td colspan="2"></td>
                        <td colspan="4">Summary for transaction type {{ item.transaction_type }} ({{ item.numRecords }}
                            records)
                        </td>
                        <td colspan="2" class="currency" style="padding-right: 20px;">
                            {{ item.totalAmount | currency }}
                        </td>
                    </tr>
                    <tr v-else-if="item.rowType == 'hotel-footer'" class="hotel-footer">
                        <td colspan="7" class="smaller">Summary for Hotel: {{ item.hotelId }} ({{ item.numRecords }}
                            records)
                        </td>
                        <td class="currency">{{ item.totalAmount | currency }}</td>
                    </tr>

                </template>
                <tr class="footer">
                    <td colspan="2">Grand Total</td>
                    <td colspan="5"></td>
                    <td class="currency">{{ total | currency }}</td>
                </tr>
            </table>
        </div>

        <b-modal id="editModal" title="Transaction Form" hide-footer>
            <entry-form name="entryFormComponent"
                        ref="entryFormContent" :folioNumbers="folioNumbers" :companyId="companyId" :hotels="hotels"
            ></entry-form>
        </b-modal>
    </div>

</template>
<script>
import EntryForm from "./admin/EntryForm";

export default {
    name: "AdminBatchDetail.vue",
    props: {
        'data': String
    },
    components: {
        EntryForm
    },
    data() {
        return {
            companyId: null,
            folioNumber: null,
            batch: [],
            total: 0,
            processed: null,
            folioNumbers: {},
            hotels: [],
        }
    },
    methods: {
        editLink(item) {

            this.$bvModal.show('editModal');
            setTimeout(()=>{
                this.$root.$emit('update-entry-form-activate', item);
            }, 200);


        },
        collectionItems(batch) {
            let rows = [];
            let batchKeys = Object.keys(batch);
            for (let x = 0; x < batchKeys.length; x++) {
                const hotelId = batchKeys[x]
                const hotel = batch[hotelId];
                rows.push(this.hotelHeaderRow(batchKeys[x]));
                const types = Object.keys(hotel.types);
                for (let y = 0; y < types.length; y++) {
                    const type = types[y];
                    const typeRow = hotel.types[type];
                    const transactions = typeRow.transactions;
                    for (let z = 0; z < transactions.length; z++) {
                        rows.push(this.dataRow(transactions[z]));
                    }
                    rows.push(this.typeFooterRow(type, typeRow.num, typeRow.total))
                }
                rows.push(this.hotelFooterRow(hotelId, hotel.num, hotel.total));
            }
            return rows;
        },
        hotelHeaderRow(hotelId) {
            return {
                'rowType': 'hotel-header',
                'hotelId': hotelId
            }
        },
        hotelFooterRow(hotelId, numRecords, totalAmount) {
            return {
                rowType: 'hotel-footer',
                hotelId: hotelId,
                numRecords: numRecords,
                totalAmount: totalAmount
            }
        },
        typeFooterRow(type, numRecords, totalAmount) {
            return {
                rowType: 'type-footer',
                transaction_type: type,
                numRecords: numRecords,
                totalAmount: totalAmount
            }
        },
        dataRow(transaction) {
            let row = JSON.parse(JSON.stringify(transaction));
            row['rowType'] = 'data-row';
            return row;

        },
        markAsProcessed() {
            Vue.$confirm({
                title: 'Are you sure?',
                message: 'Are you sure you want to mark this batch as processed?',
                button: {
                    yes: 'Yes',
                    no: 'Cancel'
                },
                callback: confirm => {
                    if (confirm) {
                        let url = '/admin/mark-processed/' + this.companyId + '/' + this.folioNumber;
                        document.location = url;
                    }
                }
            })
        }

    },
    mounted() {
        let jsonData = JSON.parse(this.data);
        this.companyId = jsonData.companyId;
        this.folioNumber = jsonData.folioNumber;
        this.total = jsonData.total;
        this.batch = this.collectionItems(jsonData.batch);
        this.processed = jsonData.processed;
        this.hotels = jsonData.hotels;
    }
}
</script>

<style scoped>
.header1 {
    background-color: rgba(29, 104, 167, 0.50);
    font-weight: bold;
    margin-bottom: 10px;
}

tr.hotel-header {
    background-color: #cccccc;
    font-weight: bold;
}

tr.header {
    background-color: #333;
    color: #fff;
    font-weight: bold;
}

tr.data-row {
    background-color: #fff;
}

tr.type-footer {
    background-color: #ddd;
    font-style: italic;
    color: #666;

}

.smaller {
    font-size: 10px !important;
}

tr.type-footer td {
    font-size: 10px !important;
}

tr.hotel-footer {
    background-color: #c5c5c5;
    margin-bottom: 20px;
}

tr.footer {
    background-color: rgba(29, 104, 167, 0.50);
    font-weight: bold;
    margin-bottom: 10px;
}

td.currency {
    text-align: right;
}
</style>
