<template>
    <div>
        <h2>Monthly Report</h2>
        <b-spinner label="Loading..." v-if="loading"></b-spinner>
        <div v-else>
            <b-table-simple id="my-table" v-if="data.length > 0 ">
                <b-thead>
                    <b-tr>
                        <b-th>Year</b-th>
                        <b-th>Month</b-th>
                        <b-th>Transactions</b-th>
                        <b-th>Amount</b-th>
                        <b-th>&nbsp;</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr
                        v-for="(row) in data"
                        :key="'monthly_row' + row.year + '_' + row.month">

                        <b-td>{{ row.year }}</b-td>
                        <b-td>{{ monthName(row.month) }}</b-td>
                        <b-td>{{ row.num_transactions }}</b-td>
                        <b-td>{{ row.sum_amount | currency }}</b-td>
                        <b-td>
                            <b-link @click="showDetails(row.year, row.month)">View</b-link>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </div>
    </div>
</template>

<script>
export default {
    name: "MonthlyReport",
    props: {
        companyId: String,
        showingMonthly: Boolean,
    },
    data() {
        return {
            loading: false,
            data: []
        };
    },
    watch: {
        companyId() {
            this.loadData(this.companyId);
        }
    },
    methods: {
        monthName(monthId) {
            const months = {
                1: 'Jan', 2: 'Feb', 3: 'Mar',
                4: 'Apr', 5: 'May', 6: 'Jun',
                7: 'Jul', 8: 'Aug', 9: 'Sep',
                10: 'Oct', 11: 'Nov', 12: 'Dec'
            };
            return months[monthId];
        },
        loadData(companyId) {
            let url = '/monthly-report/' + companyId
            this.loading = true;
            axios.get(url)
                .then((response) => {
                    this.data = response.data;
                    this.loading = false;
                });
        },
        showDetails(year, month){
            if(month < 10){
                month = '0' + month;
            }
            const monthYear = year + '-' + month;
            const url = '/search/' + this.companyId + '/view?monthyear=' + monthYear;
            window.open(url);

        }
    },
    computed: {},
    created() {
        this.$root.$on('batch-list-activate', this.batchListActivate);
    },
    mounted() {
        this.loadData(this.companyId);
    }


}
</script>

<style scoped>

</style>
