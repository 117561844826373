<template>
    <b-table-simple>
        <b-tr>
            <b-th>Hotel</b-th>
            <b-th>Type</b-th>
            <b-th>Payee</b-th>
            <b-th>Reference</b-th>
            <b-th>Invoice #</b-th>
            <b-th>Deposit Date</b-th>
            <b-th>Check Number</b-th>
            <b-th>Amount</b-th>
        </b-tr>
        <b-tr v-for="row in data" :key="'row_' + row.id ">
            <b-td>{{ row.hotel.hotel_name }} ({{ row.hotel.hotel_id }})</b-td>
            <b-td>{{ row.transaction_type }}</b-td>
            <b-td>{{ row.paid_by }}</b-td>
            <b-td>{{ row.reference }}</b-td>
            <b-td>{{ row.invoice_number }}</b-td>
            <b-td>{{ row.deposit_date }}</b-td>
            <b-td>{{ row.check_number }}</b-td>
            <b-td>
                <nobr>{{ row.deposit_amount | currency }}</nobr>
            </b-td>
        </b-tr>
    </b-table-simple>
</template>

<script>
export default {
    name: "TransactionsTable",
    components: {},
    props: {
        data: Array
    },
    data() {
        return {}
    },
    computed: {},
    methods: {},
    created() {

    }


}
</script>

<style scoped>
</style>
