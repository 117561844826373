<template>
    <div>
        <b-overlay :show="state==='loading'">
            <br/><br/><br/>

            <vue-multiselect
                v-model="selectedCompany"
                :options="getCompanies()"
                track-by="company_id"
                :custom-label="selectLabel"
                placeholder="Pick a value"
            ></vue-multiselect>
            <br/><br/><br/>
            <b-button v-if="selectedCompany !== null" variant="primary" @click="go">Go There</b-button>
        </b-overlay>

    </div>
</template>

<script>
import VueMultiselect from "vue-multiselect";

export default {
    name: "BackdoorEntry",
    components: {
        VueMultiselect
    },
    props: {
        companies: String
    },
    data() {
        return {
            state: 'ready',
            selectedCompany: null,
        }
    },
    methods: {
        getCompanies() {
            return JSON.parse(this.companies);
        },
        selectLabel({company_id, company_name}) {
            return `${company_id} — [${company_name}]`
        },
        go() {
            let url = window.location.pathname
            let data = {company_id: this.selectedCompany.company_id}
            this.state = 'loading';
            let promise = axios.post(url, data);
            promise.then((response) => {
                window.location = url.replace('/backdoor', '/');
                this.state = 'ready';
            });

        }
    },


}
</script>

<style scoped>

</style>
