<template>
    <div>
        <vue-multiselect
            v-model="selectedCompany"
            :options="companies"
            track-by="company_id"
            :custom-label="selectLabel"
            placeholder="Select a company"
            @input="companyChange()"
        ></vue-multiselect>
    </div>
</template>

<script>
export default {
    name: "CompanyList",
    props: {
        companies: {"company_id": null},
    },
    data() {
        return {
            selectedCompany: null
        }
    },
    methods: {
        selectLabel({company_id, company_name}) {
            return `${company_name} — [${company_id}]`
        },
        companyChange() {
            const company_id = (this.selectedCompany === null) ? '' : this.selectedCompany.company_id;
            this.setCookie('selectedCompany', JSON.stringify(this.selectedCompany))
            this.$root.$emit('company-change', company_id)
        }
    },
    mounted() {
        let company = this.getCookie('selectedCompany');
        if (company !== '') {
            this.selectedCompany = JSON.parse(company);
            this.companyChange();
        }

    }
}
</script>

<style scoped>

</style>
