<template>
    <div>
        <flash-messages></flash-messages>
        <b-button variant="primary" size="sm" class="float-lg-right" @click="newCompany()">Add Company</b-button>
        <b-table-simple>
            <b-thead>
                <b-tr>
                    <b-th>Company ID</b-th>
                    <b-th>Company Name</b-th>
                    <b-th>User</b-th>
                    <b-th>Password</b-th>
                    <b-th></b-th>
                </b-tr>
            </b-thead>
            <b-tbody>

                <b-tr
                    v-for="company in companyList"
                    :key="'company_row' + company.company_id">
                    <b-td>{{ company.company_id }}</b-td>
                    <b-td>{{ company.company_name }}</b-td>
                    <b-td>{{ company.users[0].username }}</b-td>
                    <b-td>{{ company.users[0].password }}</b-td>
                    <b-td>
                        <b-button variant="primary" size="sm" @click="companyForm(company)">Edit</b-button>
                    </b-td>
                </b-tr>
            </b-tbody>

        </b-table-simple>
<company-form></company-form>
    </div>
</template>

<script>

import CompanyForm from "./admin/CompanyForm";
import FlashMessages from "./FlashMessages";

export default {
    name: "AdminUsers",
    components: {
        FlashMessages,
        CompanyForm,
    },
    props: {
        companies: String
    },
    data() {
        return {}
    },
    computed: {
        companyList() {
            return JSON.parse(this.companies);
        },
    },
    methods: {
        newCompany() {
            let company = {
                flag: 'add',
                company_id: '',
                company_name: '',
                users: [
                    {
                        id: 0,
                        username: '',
                        password: '',
                    }
                ]
            };
            this.companyForm(company);
        },

        companyForm(company) {
            // alert(JSON.stringify(company));
            company.flag = 'update';
            this.$bvModal.show('company-form');
            this.$root.$emit('set-company-form', company);
        }
    }
}
</script>

<style scoped>

</style>
