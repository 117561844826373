<template>

    <b-overlay :show="this.state==='loading'">
        <div v-if="companyId===''">Please select a company</div>
        <div v-else>
            <b-button variant="primary" class="float-right" @click="addHotel">Add Hotel</b-button>
            <div v-if="hotels.length === 0">No Hotels</div>
            <b-table
                v-else
                :items="hotels"
                :fields="fields"
            >
                <template #cell(edit)="data">
                    <b-link @click="editClick(data)">
                        <b-icon-pencil-fill></b-icon-pencil-fill>
                    </b-link>
                </template>
                <template #cell(delete)="data">
                    <b-link class="float-right" @click="deleteClick(data)">
                        <b-icon-trash-fill></b-icon-trash-fill>
                    </b-link>
                </template>
            </b-table>
        </div>
        <hotel-form></hotel-form>
    </b-overlay>

</template>

<script>
import HotelForm from "./HotelForm";

export default {
    name: "Hotels",
    components: {HotelForm},
    props: {
        companyId: String,
        hotels: Array,
    },
    data() {
        return {
            state: 'ready',
            fields: [
                {
                    key: 'edit',
                    label: ''
                },
                {
                    key: 'hotel_id',
                    sortable: true
                },
                {
                    key: 'hotel_name',
                    sortable: true
                },
                {
                    key: 'contact_name',
                    sortable: true
                },
                {
                    key: 'delete',
                    label: ''
                },

            ],
        }
    },
    methods: {
        editClick(data) {
            this.hotelForm(data.index, data.item);
        },
        deleteClick(data) {
            console.log(data.item.hotel_id)
            alert(data.item.hotel_id)
        },
        addHotel() {
            let newHotel = {
                id: 0,
                company_id: '',
                hotel_id: '',
                hotel_name: '',
                contact_name: '',
                title: '',
                city: '',
                state: '',
                zip: '',
                region: '',
                country: '',
                phone: '',
                fax: ''
            };
            this.hotelForm(-1, newHotel);
        },
        hotelForm(idx, hotel) {
            let object = {idx: -1, hotel: hotel};
            this.$root.$emit('set-hotel-form', object)
            this.$bvModal.show('hotel-form')
        }

    },
    created() {

    }
}
</script>

<style scoped>

</style>

