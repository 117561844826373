<template>
    <div>
        <div class="alert alert-success" v-for="message in success">{{ message }}</div>
        <div class="alert alert-danger" v-for="message in danger">{{ message }}</div>
        <div class="alert alert-warning" v-for="message in warning">{{ message }}</div>
    </div>
</template>

<script>
export default {
    name: "FlashMessages",
    data() {
        return {
            'keys': ['success', 'danger', 'warning'],
            'success': [],
            'danger': [],
            'warning': [],
        }
    }, methods: {
        message(message, type){
            this[type].push(message);
            setTimeout(()=>{
                this[type].splice(0,1);
            }, 5000);
        },
        dangerMessage(message){
            this.message(message, 'danger');
        },
        warningMessage(message){
            this.message(message, 'warning');
        },
        successMessage(message){
            this.message(message, 'success');
        },
    },
    mounted(){

    },
    created() {
        this.$root.$on('danger-message', this.dangerMessage);
        this.$root.$on('warning-message', this.warningMessage);
        this.$root.$on('success-message', this.successMessage);

    }
}
</script>

<style scoped>

</style>
