<template>
    <b-modal id="hotel-form" title="Hotel Form" size="lg" show-foot>
        <b-overlay :show="state==='loading'">
            <b-form @submit="saveHotel">

                <b-row>
                    <b-col>
                        <b-form-group>
                            <label>Hotel ID:<span class="required">*</span></label>
                            <b-form-input
                                v-model="hotel.hotel_id"
                                :class="{required: validElement('hotel_id')}"
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group>
                            <label>Hotel Name:<span class="required">*</span></label>
                            <b-form-input
                                v-model="hotel.hotel_name"
                                :class="{required: validElement('hotel_name')}"
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group label="Contact Name:">
                            <b-form-input v-model="hotel.contact_name"></b-form-input>
                        </b-form-group>
                        <b-form-group label="Title:">
                            <b-form-input v-model="hotel.title"></b-form-input>
                        </b-form-group>
                        <b-form-group label="Phone:">
                            <b-form-input v-model="hotel.phone"></b-form-input>
                        </b-form-group>

                        <b-form-group label="Fax:">
                            <b-form-input v-model="hotel.fax"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group label="Address:">
                            <b-form-input v-model="hotel.address"></b-form-input>
                        </b-form-group>
                        <b-form-group label="City:">
                            <b-form-input v-model="hotel.city"
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group label="State:">
                            <b-form-input v-model="hotel.state"></b-form-input>
                        </b-form-group>

                        <b-form-group label="Zip:">
                            <b-form-input v-model="hotel.zip"></b-form-input>
                        </b-form-group>

                        <b-form-group label="region:">
                            <b-form-input v-model="hotel.region"></b-form-input>
                        </b-form-group>

                        <b-form-group label="Country:">
                            <b-form-input v-model="hotel.country"></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>

            </b-form>
        </b-overlay>
        <template #modal-footer>
            <div>
                <b-button variant="secondary" size="sm" @click="close">Cancel</b-button>
                <b-button variant="primary" size="sm" @click="saveHotel">Save</b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>
export default {
    name: "HotelForm",
    data() {
        return {
            dirty: false,
            state: 'ready',
            parentIndex: 0,
            hotel: {
                id: 0,
                company_id: '',
                hotel_id: '',
                hotel_name: '',
                contact_name: '',
                title: '',
                city: '',
                state: '',
                zip: '',
                region: '',
                country: '',
                phone: '',
                fax: ''
            }
        }
    },
    methods: {
        saveHotel() {
            if (!this.validForm()) {
                this.state = 'loading';
                let url = window.location.pathname + '/proc/hotel'
                let promise = axios.post(url, this.hotel)
                promise.then((response) => {
                    this.state = 'ready';
                    this.hotel = response.data.hotel;
                    this.$root.$emit('hotel-saved', {idx: this.parentIndex, hotel: this.hotel});
                    this.close();
                });

            }
        },
        validForm() {
            return this.validElement('hotel_id') && this.validElement('hotel_name')
        },
        validElement(field) {
            return this.hotel[field].trim() == ''
        },
        setHotel(object) {
            this.parentIndex = object.idx;
            this.hotel = object.hotel;
        },
        close() {
            this.$bvModal.hide('hotel-form')
        },

    },
    created() {
        this.$root.$on('set-hotel-form', this.setHotel);
    }
}
</script>

<style scoped>
span.required {
    color: red;
}

input.required {
    border: 1px solid red;
}
</style>
