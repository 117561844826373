<template>
    <div>
        <b-overlay :show="status==='loading'">
            <b-row>
                <b-col>
                    <b-form-group>
                        <label>Type: </label>
                        <vue-multiselect
                            style="width: 120px"
                            v-model="type"
                            :options="typeList"
                            placeholder="Select a Type"
                            :searchable="false"
                            :show-labels="false"
                            :allow-empty="false"
                        ></vue-multiselect>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group>
                        <label>From Date: </label>
                        <datepicker placeholder="Select Date" v-model="fromDate"></datepicker>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group>
                        <label>To Date: </label>
                        <datepicker placeholder="Select Date" v-model="toDate"></datepicker>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-button style="margin-top: 25px" variant="primary" @click="search()">Search</b-button>
                </b-col>
            </b-row>
        </b-overlay>

        <b-overlay :show="status === 'loading-data'">
            <div v-if="batch === null || batch.length === 0 && status === 'ready'"> No Transactions</div>
            <table class="col-12" v-if="batch.length>0">
                <tr class="header">
                    <th>Hotel ID</th>
                    <th>Type</th>
                    <th>Paid By</th>
                    <th>Reference</th>
                    <th>Check #</th>
                    <th>Deposit Date</th>
                    <th>Deposit Amount</th>
                </tr>
                <template v-for="item in batch">
                    <tr v-if="item.rowType == 'hotel-header'" class="hotel-header">
                        <td colspan="7">{{ item.hotelId }}</td>
                    </tr>
                    <tr v-else-if="item.rowType == 'data-row'" class="data-row">
                        <td></td>
                        <td>{{ item.transaction_type }}</td>
                        <td>{{ item.paid_by }}</td>
                        <td>{{ item.reference }}</td>
                        <td>{{ item.check_number }}</td>
                        <td>{{ item.deposit_date }}</td>
                        <td class="currency">{{ item.deposit_amount |currency }}</td>
                    </tr>
                    <tr v-else-if="item.rowType == 'type-footer'" class="type-footer">
                        <td colspan="2"></td>
                        <td colspan="3">Summary for transaction type {{ item.transaction_type }} ({{ item.numRecords }}
                            records)
                        </td>
                        <td colspan="2" class="currency" style="padding-right: 20px;">
                            {{ item.totalAmount | currency }}
                        </td>
                    </tr>
                    <tr v-else-if="item.rowType == 'hotel-footer'" class="hotel-footer">
                        <td colspan="6" class="smaller">Summary for Hotel: {{ item.hotelId }} ({{ item.numRecords }} records)</td>
                        <td class="currency">{{ item.totalAmount | currency }}</td>
                    </tr>

                </template>

            </table>

        </b-overlay>

    </div>
</template>

<script>
export default {
    name: "TypeReport",
    props: {
        companyId: String,
    },
    data() {
        return {
            status: 'ready',
            type: '',
            typeList: [],
            fromDate: null,
            toDate: null,
            batchList: [],
            batch: [],
        };
    },
    computed: {
        getTypes() {
            let items = [];
            this.typeList.forEach((type) => {
                items.push({
                    'id': type,
                    'value': type
                });

            });
            return items;
        }
    },
    methods: {
        processedLabel(processed) {
            return (processed) ? "Processed" : "Unprocessed"
        },
        collectionItems(batch) {
            let rows = [];
            let batchKeys = Object.keys(batch);
            for (let x = 0; x < batchKeys.length; x++) {
                const hotelId = batchKeys[x]
                const hotel = batch[hotelId];
                rows.push(this.hotelHeaderRow(batchKeys[x]));
                const types = Object.keys(hotel.types);
                for (let y = 0; y < types.length; y++) {
                    const type = types[y];
                    const typeRow = hotel.types[type];
                    const transactions = typeRow.transactions;
                    for (let z = 0; z < transactions.length; z++) {
                        rows.push(this.dataRow(transactions[z]));
                    }
                    rows.push(this.typeFooterRow(type, typeRow.num, typeRow.total))
                }
                rows.push(this.hotelFooterRow(hotelId, hotel.num, hotel.total));
            }
            return rows;
        },
        hotelHeaderRow(hotelId) {
            return {
                'rowType': 'hotel-header',
                'hotelId': hotelId
            }
        },
        hotelFooterRow(hotelId, numRecords, totalAmount) {
            return {
                rowType: 'hotel-footer',
                hotelId: hotelId,
                numRecords: numRecords,
                totalAmount: totalAmount
            }
        },
        typeFooterRow(type, numRecords, totalAmount) {
            return {
                rowType: 'type-footer',
                transaction_type: type,
                numRecords: numRecords,
                totalAmount: totalAmount
            }
        },
        dataRow(transaction) {
            let row = JSON.parse(JSON.stringify(transaction));
            row['rowType'] = 'data-row';
            return row;

        },

        loadTypeList() {
            this.status = 'loading';
            let url = this.currentUrl + '/type-list/' + this.companyId;
            let promise = axios.get(url);
            promise.then((response) => {
                this.typeList = response.data.list;
                this.type = this.typeList[0];
                this.status = 'ready';
            });
        },
        formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [year, month, day].join('-');
        }
        ,
        search() {
            let url = this.currentUrl + '/search/' + this.companyId;
            let data = {
                type: this.type,
                fromDate: this.formatDate(this.fromDate),
                toDate: this.formatDate(this.toDate),
            };
            this.status = 'loading-data';
            let promise = axios.post(url, data);
            promise.then((response) => {
                this.batch = this.collectionItems(response.data.data.batch);
                this.status = 'ready'
            })
        }
    },
    mounted() {
        this.loadTypeList();
        this.toDate = new Date();
        this.fromDate = new Date().setDate(this.toDate.getDate() - 90);

    }


}
</script>

<style scoped>
tr.hotel-header {
    background-color: #cccccc;
    font-weight: bold;
}

tr.header {
    background-color: #333;
    color: #fff;
    font-weight: bold;
}

tr.data-row {
    background-color: #fff;
}

tr.type-footer {
    background-color: #ddd;
    font-style: italic;
    color: #666;

}
.smaller
{
    font-size: 10px !important;
}
tr.type-footer td
{
    font-size: 10px !important;
}

tr.hotel-footer {
    background-color: #c5c5c5;
    margin-bottom: 20px;
}

tr.footer {
    background-color: rgba(29, 104, 167, 0.50);
    font-weight: bold;
    margin-bottom: 10px;
}

td.currency {
    text-align: right;
}
</style>
