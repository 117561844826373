<template>
    <div style="padding-top: 8px;">
        <b-overlay :show="status === 'loading'">
            <div v-if="batchList.length === 0 && status === 'ready'"> No Batches</div>
            <b-pagination
                v-if="batchList.length > perPage"
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="my-table"
            ></b-pagination>

            <b-table-simple v-if="batchList.length > 0 ">
                <b-thead>
                    <b-tr>
                        <b-th>Date</b-th>
                        <b-th>Count</b-th>
                        <b-th>Amount</b-th>
                        <b-th>Folio</b-th>
                        <b-th>Status</b-th>
                        <b-th>&nbsp;</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>

                    <b-tr
                        v-for="batch in getBatchList"
                        :key="'batch_row_' + batch.folio_number">
                        <b-td>{{ batch.transaction_date }}</b-td>
                        <b-td>{{ batch.num_transactions }}</b-td>
                        <b-td>{{ batch.amount }}</b-td>
                        <b-td>{{ batch.folio_number }}</b-td>
                        <b-td>{{ processedLabel(batch.processed) }}</b-td>
                        <b-td>
                            <a target="_blank" :href="batchLink(batch.folio_number)">View</a>
                        </b-td>
                    </b-tr>


                </b-tbody>
            </b-table-simple>
            <b-pagination
                v-if="batchList.length > perPage"
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="my-table"
            ></b-pagination>
        </b-overlay>

    </div>
</template>

<script>
export default {
    name: "AdminBatchList",
    props: {
        companyId: String,
    },
    data() {
        return {
            currentPage: 1,
            perPage: 10,
            status: 'ready',
            tabCLicks: 0,
            batchList: [],
        };
    },
    watch: {
        tabIndex() {
            this.tabCLicks++;
        }
    },
    computed: {
        rows() {
            return this.batchList.length;
        },
        getBatchList() {
            let x = 0;
            return this.batchList.filter((batchItem) => {

                let max = this.currentPage * this.perPage;
                let min = max - this.perPage;
                max--;
                let ret = x >= min && x <= max;
                x++;
                return (ret);

            })
        },
    },
    methods: {
        batchListActivate() {
            this.status = 'loading';
            let url = this.currentUrl + '/batch-list/' + this.companyId;
            let promise = axios.get(url);
            promise.then((response) => {
                this.batchList = response.data.list;
                this.status = 'ready';
            });
        },
        processedLabel(processed) {
            return (processed) ? "Processed" : "Unprocessed"
        },
        batchLink(folioNumber){
            return this.currentUrl + '/batch-detail/' + this.companyId + '/' + folioNumber
        }

    },
    created() {
        this.$root.$on('batch-list-activate', this.batchListActivate);
    },
    mounted() {
    }


}
</script>

<style scoped>

</style>
