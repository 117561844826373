<template>
    <div>
        <flash-messages></flash-messages>
        <p>
            <company-list :companies="companyList"></company-list>
        </p>
        <b-tabs content-class="mt-3" v-if="companyId !== null" v-model="tabIndex" @activate-tab="tabChange">
            <b-tab title="Hotels">
                <hotels :companyId="companyId" :hotels="hotels"></hotels>
            </b-tab>
            <b-tab title="New Entry" v-if="numHotels>0">
                <entry-form ref="entryForm" :folioNumbers="folioNumbers" :companyId="companyId"
                            :hotels="hotels"></entry-form>
            </b-tab>
            <b-tab title="Batches" v-if="numHotels>0">
                <admin-batch-list :companyId="companyId"></admin-batch-list>
            </b-tab>
            <b-tab title="Monthly Report" v-if="numHotels>0 && companyId !== null" @click="toggleShowingMonthlyReport">
                <monthly-report v-if="numHotels>0" :companyId="companyId" :showingMonthly="showingMonthly"/>
            </b-tab>
            <b-tab title="Search" v-if="numHotels>0">
                <search :companyId="companyId"/>
            </b-tab>
        </b-tabs>

    </div>

</template>

<script>

import CompanyList from "./admin/CompanyList";
import EntryForm from "./admin/EntryForm";
import Hotels from "./admin/Hotels";
import FlashMessages from "./FlashMessages";
import AdminBatchList from "./admin/AdminBatchList";
import MonthlyReport from "./MonthlyReport.vue";
import Search from "./Search.vue";

export default {
    name: "Admin",
    components: {
        MonthlyReport,
        AdminBatchList,
        FlashMessages,
        CompanyList,
        EntryForm,
        Hotels,
        Search
    },
    props: {
        companies: String
    },
    data() {
        return {
            showingMonthly: false,
            state: 'ready',
            tabIndex: 0,
            companyId: null,
            hotels: [],
            menu: null,
            menuOptions: [
                {
                    value: 'hotels',
                    label: 'Hotels'
                }, {
                    value: 'new-entry',
                    label: 'New Entry'
                }, , {
                    value: 'batches',
                    label: 'Batches'
                }, {
                    value: 'report',
                    label: 'Monthly Report'
                }, {
                    value: 'search',
                    label: 'Search'
                }
            ],
            folioNumbers: {}
        }
    },
    computed: {
        numHotels() {
            return this.hotels.length;
        },
        companyList() {
            return JSON.parse(this.companies);
        },

    },
    methods: {
        toggleShowingMonthlyReport() {
            this.showingMonthly = true;
        },
        hotelsCollected(hotels) {
            this.hotels = hotels;
        },
        collectFolioNumber(companyId) {
            if (this.folioNumbers[companyId] === undefined) {
                let url = this.currentUrl + '/next-folio/' + companyId
                let promise = axios.get(url);
                promise.then((response) => {
                    this.folioNumbers[companyId] = response.data.next_folio_number;
                });
            }

        },
        companyChange(companyId) {
            this.showingMonthly = false;
            this.companyId = companyId;
            this.tabIndex = 0;

            if (companyId === '') {
                return;
            }

            this.collectFolioNumber(companyId);
            let url = this.currentUrl + '/data/hotels/' + companyId
            let promise = axios.get(url)
            this.state = 'loading'
            promise.then((response) => {
                this.state = 'ready'
                let hotels = response.data.hotels
                this.setHotels(hotels)
                this.$root.$emit('hotels-collected', hotels);
            })
        },
        setHotels(hotels) {
            this.hotels = hotels;
        },
        hotelSaved(object) {
            if (object.idx >= 0) {
                this.hotels[idx] = object.hotel;
            } else {
                this.hotels.push(object.hotel);
            }
        },
        tabChange(newTabIndex) {
            switch (newTabIndex) {
                case 1:
                    this.newEntryClick();
                    break;
                case 2:
                    console.log('batchesClick');
                    this.batchesClick();
                    break;
            }
        },
        newEntryClick() {
            this.$root.$emit('new-entry-form-activate');
        },
        batchesClick() {
            this.$root.$emit('batch-list-activate');
        },
        monthlyView(monthYear) {
            this.tabIndex = 4;
        }

    },
    created() {
        this.$root.$on('set-hotels', this.setHotels);
        this.$root.$on('hotel-saved', this.hotelSaved);
        this.$root.$on('company-change', this.companyChange);
        this.$root.$on('hotels-collected', this.hotelsCollected);
        this.$root.$on('monthly-view', this.monthlyView);
    }


}
</script>

<style scoped>
</style>
