<template>
    <b-modal id="company-form" title="Hotel Form" size="lg" show-foot>
        <b-overlay :show="state==='loading'">
            <h2>{{ title }} - {{ prevCompanyId }}</h2>
            <b-form-group>
                <label>Company ID:<span class="required">*</span></label>
                <b-form-input
                    max="4"
                    v-model="company.company_id"
                    :class="{required: validElement('company_id')}"
                ></b-form-input>
            </b-form-group>
            <b-form-group>
                <label>Company Name:<span class="required">*</span></label>
                <b-form-input
                    max="128"
                    v-model="company.company_name"
                    :class="{required: validElement('company_name')}"
                ></b-form-input>
            </b-form-group>
            <b-form-group>
                <label>User Name:<span class="required">*</span></label>
                <b-form-input
                    max="128"
                    v-model="company.users[0].username"
                    :class="{required: validElement('username')}"
                ></b-form-input>
            </b-form-group>
            <b-form-group>
                <label>Password:<span class="required">*</span></label>
                <b-form-input
                    max="128"
                    v-model="company.users[0].password"
                    :class="{required: validElement('password')}"
                ></b-form-input>
            </b-form-group>
        </b-overlay>

        <template #modal-footer>
            <div>
                <b-button variant="secondary" size="sm" @click="close">Cancel</b-button>
                <b-button variant="primary" size="sm" @click="saveCompany">Save</b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>
export default {
    name: "CompanyForm",
    props: {},
    data() {
        return {
            prevCompanyId: '',
            state: 'ready',
            company: {
                flag: 'add',
                company_id: '',
                company_name: '',
                users: [
                    {
                        id: 0,
                        username: '',
                        password: '',
                    }
                ]
            }
        };
    },
    computed: {
        title() {
            return this.company.flag.charAt(0).toUpperCase() + this.company.flag.slice(1) + ' Company';
        },
    },
    methods: {
        setCompany(company) {
            this.prevCompanyId = this.lodash.clone(company.company_id);
            this.company = company;
        },
        close() {
            this.$bvModal.hide('company-form')
        },
        validElement(field) {
            return false;
        },
        validForm() {
            return false;
        },
        saveCompany() {
            if (!this.validForm()) {
                this.state = 'loading';
                let data = {
                    company_id: this.prevCompanyId,
                    company: this.company
                }
                let url = window.location.pathname
                let promise = axios.post(url, data)
                promise.then((response) => {
                    if (response.data.dupes) {
                        this.state = 'ready';
                        let msg = [];
                        if (response.data.companyDupes) {
                            msg.push("This company id is already in use")
                        }
                        if (response.data.userDupes) {
                            msg.push("This username is already in use")
                        }
                        alert(msg.join("\n"));

                    } else {
                        window.location.reload();
                    }
                });

            }


        }
    },
    created() {
        this.$root.$on('set-company-form', this.setCompany);
    }
}
</script>

<style scoped>
span.required {
    color: red;
}

input.required {
    border: 1px solid red;
}
</style>
