import Vue from "vue";

const mixin = {
    computed: {
        currentUrl() {
            let url = window.location.pathname;
            if (url[url.length - 1] === '/') {
                url = url.substr(0, url.length - 1);
            }
            return url;
        },
        baseUrl(){
            let url = this.currentUrl();
            alert(url);
            return url;
        }
    },
    methods: {
        getCookie(cname) {

            var name = cname + "=";
            var decodedCookie = decodeURIComponent(document.cookie);
            var ca = decodedCookie.split(';');
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        },
        setCookie(cname, cvalue, exdays) {
            var d = new Date();
            d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
            var expires = "expires=" + d.toUTCString();
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;SameSite=Lax"; // ; Secure
        },
        flashDanger(message) {
            this.$root.$emit('danger-message', message);
        },
        flashSuccess(message) {
            this.$root.$emit('success-message', message);
        },
        flashWarning(message) {
            this.$root.$emit('warning-message', message);
        },
    }
}
Vue.mixin(mixin);
